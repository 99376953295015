import * as linq from "linq";
import * as ReactDOM from "react-dom";
import * as constants from "../constants";
import { ERoleRight } from "../model/app/Enums";
import IAppDefinition from "../model/app/IAppDefinition";
import { IIdNameFlag } from "../model/app/IIdName";
import IUser from "../model/app/IUser";
import { IAddress } from "../screens/am/Models";
import { Roles } from "./Defaults";
import StorageHelper from "./StorageHelper";
import IKeyText from "../model/app/IKeyText";

export class Global {
	public static getConfiguration(): IAppDefinition {
		const build = constants.build;
		let apiUrl: string = "http://api-local.tradelight.net/bo/";
		let envUrl: string = "http://api-local.tradelight.net/api/";

		if (build === constants.BuildEnv.Dev) {
			apiUrl = "https://api-local.tradelight.net/bo/";
			envUrl = "https://api-local.tradelight.net/api/";
		} else if (build === constants.BuildEnv.Int) {
			apiUrl = "https://easy3.tradelight.net/r/bo/";
			envUrl = "https://easy3.tradelight.net/r/api/";
		} else if (build === constants.BuildEnv.PreProd) {
			apiUrl = "https://easy4.tradelight.net/r/bo/";
			envUrl = "https://easy4.tradelight.net/r/api/";
		} else if (build === constants.BuildEnv.Prod) {
			apiUrl = "https://api.vf-easy.de/r/bo/";
			envUrl = "https://api.vf-easy.de/r/api/";
		}

		return {
			appTitle: "EASY 3.0 Backoffice",
			apiUrl,
			envUrl,
		};
	}

	public static getEmptyGuid = () => {
		return "00000000-0000-0000-0000-000000000000";
	};

	public static getUniqueId = () => {
		let result: string = "";
		let i: string = "";
		let j: number = 0;
		result = "";
		for (j = 0; j < 32; j++) {
			if (j === 8 || j === 12 || j === 16 || j === 20) {
				result = result + "-";
			}
			i = Math.floor(Math.random() * 16)
				.toString(16)
				.toUpperCase();
			result = result + i;
		}
		return result.toLowerCase();
	};

	public static getEmptyIdModel = (val: any = undefined) => {
		return JSON.parse('{"id": "' + (val ? val : "") + '"}');
	};

	public static wait = (ms) => new Promise((res) => setTimeout(res, ms));

	public static inRole(role: string): boolean {
		let usr = StorageHelper.getUser();

		if (usr) {
			let r = linq
				.from<string>(usr.roles)
				.where((x) => x === role)
				.firstOrDefault();

			return r ? true : false;
		}
		return false;
	}

	public static filterRoles() {
		var u = StorageHelper.getUser();
		var st = StorageHelper.getStructures();

		if (u) {
			var fl: ERoleRight = ERoleRight.editable | ERoleRight.appBo;

			if (Global.inAnyRole([Roles.VB, Roles.VL])) {
				fl = fl | ERoleRight.posVl;
			}

			if (Global.inAnyRole([Roles.PortalAdministrator])) {
				fl = ERoleRight.editable;
			}

			if (
				!Global.inAnyRole([Roles.PortalAdministrator]) &&
				!Global.inAnyRole([Roles.TemoDealer]) &&
				!Global.inAnyRole([Roles.TemoBackOffice])
			) {
				return linq
					.from<IIdNameFlag>(st.roles)
					.where((x) => x.id === Roles.XTND_ShopManager)
					.orderBy((x) => x.name)
					.toArray();
			} else if (
				Global.inAnyRole([Roles.TemoDealer]) ||
				Global.inAnyRole([Roles.TemoBackOffice])
			) {
				return linq
					.from<IIdNameFlag>(st.roles)
					.where((x) => x.id === Roles.TemoDealer)
					.orderBy((x) => x.name)
					.toArray();
			} else {
				return linq
					.from<IIdNameFlag>(st.roles)
					.where((x) => (fl & x.flag) == fl)
					.orderBy((x) => x.name)
					.toArray();
			}
		}

		return [];
	}

	public static formatAddress(source: any) {
		if (source && source.address) {
			return `${source.address.street} ${source.address.nr}, ${source.address.zip} ${source.address.city} `;
		}

		return "";
	}

	public static formatAccount(source: any) {
		if (source && source.address) {
			return `${source.name} (${source.void})`;
		}

		return "";
	}

	public static toGermanAddress(a: IAddress | undefined) {
		if (a) {
			return `${a.street} ${a.nr}, ${a.zip} ${a.city}`;
		}

		return "";
	}

	public static toIsoStringCustom(date: Date) {
		var tzo = -date.getTimezoneOffset(),
			dif = tzo >= 0 ? "+" : "-",
			pad = function (num) {
				return (num < 10 ? "0" : "") + num;
			};

		return (
			date.getFullYear() +
			"-" +
			pad(date.getMonth() + 1) +
			"-" +
			pad(date.getDate()) +
			"T" +
			pad(date.getHours()) +
			":" +
			pad(date.getMinutes()) +
			":" +
			pad(date.getSeconds()) +
			dif +
			pad(Math.floor(Math.abs(tzo) / 60)) +
			":" +
			pad(Math.abs(tzo) % 60)
		);
	}

	public static toGermanDate(
		value: Date | undefined,
		withTime?: boolean | undefined
	): string {
		if (!value) {
			return "";
		}

		let inner: Date = new Date(value);
		let dd: number = inner.getDate();
		let mm: number = inner.getMonth() + 1;
		let yyyy: number = inner.getFullYear();
		let d: string = dd.toString();
		let m: string = mm.toString();

		if (dd < 10) {
			d = "0" + dd;
		}

		if (mm < 10) {
			m = "0" + mm;
		}
		var time = "";
		if (withTime) {
			time =
				" " +
				Global.pad(inner.getHours(), 2) +
				":" +
				Global.pad(inner.getMinutes(), 2) +
				":" +
				Global.pad(inner.getSeconds(), 2);
		}

		return d + "." + m + "." + yyyy + time;
	}

	public static toUTC(oldDate: Date) {
		return new Date(
			Date.UTC(
				oldDate.getFullYear(),
				oldDate.getMonth(),
				oldDate.getDate(),
				oldDate.getHours(),
				oldDate.getMinutes(),
				oldDate.getSeconds()
			)
		);
	}

	public static pad = (num: number, size: number): string => {
		let s = num + "";
		while (s.length < size) s = "0" + s;
		return s;
	};

	public static inAnyRole(roles: string[]): boolean {
		let usr = StorageHelper.getUser();
		if (usr) {
			let r = linq
				.from<string>(usr.roles)
				.where((x) => roles.indexOf(x) >= 0)
				.toArray();

			return r && r.length ? true : false;
		}
		return false;
	}

	public static inAnyRoleUser(
		user: IUser | undefined,
		roles: string[]
	): boolean {
		if (user) {
			let r = linq
				.from<string>(user.roles)
				.where((x) => roles.indexOf(x) >= 0)
				.toArray();

			return r && r.length ? true : false;
		}
		return false;
	}

	public static FindReact(dom: any) {
		let k = Object.keys(dom).find((key) =>
			key.startsWith("__reactInternalInstance$")
		);
		let internalInstance = dom[k ? k : 0];
		if (internalInstance == null) {
			return null;
		}

		if (internalInstance.return) {
			// react 16+
			return internalInstance._debugOwner
				? internalInstance._debugOwner.stateNode
				: internalInstance.return.stateNode;
		} else {
			// react <16
			return internalInstance._currentElement._owner._instance;
		}
	}

	public static GetComponent<T>(id: string): T {
		let element = document.getElementById(id);
		debugger;
		let ctrl: T = Global.FindReact(element);
		return ctrl;
	}

	public static arrayRemove(arr: any[], what: any) {
		var L = arr.length,
			ax;
		while (L && arr.length) {
			while ((ax = arr.indexOf(what)) !== -1) {
				arr.splice(ax, 1);
			}
			L--;
		}
		return this;
	}

	public static getAppUrl(): string {
		return window.location.protocol + "//" + window.location.host;
	}

	public static setContainer(comp: JSX.Element, id: string) {
		if (id == null || id === "") {
			id = "container";
		}
		let cont = document.getElementById(id);
		ReactDOM.render(comp as any, cont);
	}

	public static setValueByPath(target: any, path: string, value: string) {
		let sa = path.split(".");
		if (sa.length === 1) {
			target[path] = value;
		} else {
			let child = sa[0];
			if (!target[child]) {
				target[child] = {};
			}
			sa.splice(0, 1);
			this.setValueByPath(target[child], sa.join("."), value);
		}
	}

	public static getEnumKeyByEnumValue = (
		enumObject: any,
		enumValue: number | string
	): string => {
		let keys = Object.keys(enumObject).filter(
			(x) => enumObject[x] === enumValue
		);
		return keys.length > 0 ? keys[0] : "";
	};

	public static getKeyValuesFromEnum = (
		enumObject: any,
		translate?: boolean,
		filteredValues?: number[]
	): IKeyText[] => {
		const stringIsNumber = (value) => isNaN(Number(value)) === false;
		const keys = Object.keys(enumObject)
			.filter(stringIsNumber)
			.map((x) => Number.parseInt(x));

		return keys.flatMap((e) => {
			if (!filteredValues) {
				return [
					{
						key: Number(e),
						//translate
						text:
							//? EnumTexts.getString(enumObject[e], undefined, false) || enumObject[e]
							//: enumObject[e]
							enumObject[e],
					} as IKeyText,
				];
			} else if (filteredValues.indexOf(e) !== -1) {
				return [
					{
						key: Number(e),
						//translate
						text:
							// 	? EnumTexts.getString(enumObject[e], undefined, false) ||
							// 	  enumObject[e]
							// 	: enumObject[e],
							enumObject[e],
					} as IKeyText,
				];
			} else return [];
		});
	};
}

export default Global;

import { initializeIcons } from '@uifabric/icons';
import { IRawStyle, mergeStyles, mergeStyleSets } from '@uifabric/merge-styles';
import { SearchBox, Check, Dialog, DialogFooter, DialogType, PrimaryButton, TooltipHostBase } from 'office-ui-fabric-react';
import { Checkbox, Dropdown, Fabric, FontIcon, IDropdownOption, ITextFieldStyles, Label, Link, MessageBar, MessageBarType, Panel, PanelType, Pivot, PivotItem, ProgressIndicator, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react/lib';
import { DetailsList, DetailsListLayoutMode, DetailsRow, IColumn, Selection, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Text } from 'office-ui-fabric-react/lib/Text';
import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../src/App.css';
import { EScreenMode } from '../model/app/Enums';
import { IApiResponse } from '../model/app/IApiResponse';
import { mTanHandlerModel, MoreInfoShop } from '../model/data/HandlerModel';
import { ShopEmployeeModel } from '../model/data/ShopEmployeeModel';
import Loader from '../Support/Loader';
import Api from '../utils/Api';
import Global from '../utils/Global';
import { EmployeeList } from './employeeList';
import { TablePaging } from './paging';
import { Roles } from '../utils/Defaults';
import { ExportCSV } from '../components/controls/ExportCSV'
import { throwStatement } from '@babel/types';
import { ErrorScreen } from '.';
import App from '../App';

// Initialize icons in case this example uses them
initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/');

const classNames = mergeStyleSets({
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    }
})

export interface IPagingModel {
    page: number;
    count: number;
}

interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    handlerItems: mTanHandlerModel[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
    announcedMessage?: string;
    mode: EScreenMode,
    changed: boolean,
    hidden: boolean,
    disableButtonNameField: boolean,
    lastSelectedPivot: string | undefined,
    spinner: boolean,
    isPanelOpen: boolean,
    isAddDialog: boolean,
    isRemoveDialog: boolean,
    error: string,
    isOKVisible: boolean,
    errorResponse: boolean;
    selectedHandler: mTanHandlerModel;
}


class MTanValidation extends React.Component<{}, IDetailsListDocumentsExampleState> {
    private url: string = "activationdata/";
    private _allHandlerItems: mTanHandlerModel[];
    private _selection: Selection;
    private totalItems: number = 0;
    private addVoidToMTan: string = '';

    
    constructor(props: {}) {
        super(props);

        this.onDataArrival = this.onDataArrival.bind(this);
        this.addMTan = this.addMTan.bind(this);
        this.removeMTan = this.removeMTan.bind(this);
        
        this._allHandlerItems = [];
        const columns: IColumn[] = [
            {
                key: 'accountID',
                name: 'VOID',
                ariaLabel: 'VOID',
                fieldName: 'accountID',
                minWidth: 70,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 90,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'companyName',
                name: 'Firmenname',
                ariaLabel: 'Firmenname',
                fieldName: 'companyName',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'address',
                name: 'Adresse',
                ariaLabel: 'Adresse',
                fieldName: 'address',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'city',
                name: 'Stadt',
                ariaLabel: 'Stadt',
                fieldName: 'city',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'zip',
                name: 'ZIP',
                ariaLabel: 'ZIP',
                fieldName: 'zip',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'salesRepresentative',
                name: 'VB',
                ariaLabel: 'VB',
                fieldName: 'salesRepresentative',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'salesManager',
                name: 'VL',
                ariaLabel: 'VL',
                fieldName: 'salesManager',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'level_1',
                name: 'L1',
                ariaLabel: 'L1',
                fieldName: 'level_1',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'level_2',
                name: 'L2',
                ariaLabel: 'L2',
                fieldName: 'level_2',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'level_3',
                name: 'L3',
                ariaLabel: 'L3',
                fieldName: 'level_3',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'level',
                name: 'Level',
                ariaLabel: 'Level',
                fieldName: 'level',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'registrationDate',
                name: 'Anmeldedatum',
                ariaLabel: 'Anmeldedatum',
                fieldName: 'registrationDate',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'tanAuthDateSet',
                name: 'mTan Datum',
                ariaLabel: 'mTan Datum',
                fieldName: 'tanAuthDateSet',
                minWidth: 90,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                maxWidth: 110,
                onColumnClick: this._onColumnClick
            }
        ];

        this._selection = new Selection({
            onSelectionChanged: () => {
                let id: string = this._selection.getSelection()[0] === undefined ? '' : (this._selection.getSelection()[0] as mTanHandlerModel).accountID;

                // show Dialog only if a Handler from the list is clicked
                if (id != '') {
                    this.showRemoveDialog();
                }
            }
        });

        this.state = {
            handlerItems: this._allHandlerItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: false,
            announcedMessage: "",
            mode: EScreenMode.loading,
            changed: false,
            hidden: true,
            lastSelectedPivot: undefined,
            disableButtonNameField: true,
            spinner: false,
            isPanelOpen: false,
            isRemoveDialog: false,
            isAddDialog: false,
            error: '',
            isOKVisible: false,
            errorResponse: true,
            selectedHandler : new mTanHandlerModel()
        };

        Api.fetch(this.url + 'getAccountsWithMTanValidation?', undefined, "GET", this.onDataArrival);
    }

    public render() {
        const inRole = Global.inAnyRole([Roles.FraudMgmt, Roles.PortalAdministrator]);
        const { columns, isCompactMode, handlerItems, isModalSelection } = this.state;

        if (!inRole) {
            return <ErrorScreen data={{ code: 403 }} />;
        }

        return (
            this.state.mode === EScreenMode.loading ? 
                <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" /> :
                this.state.hidden === false ?
                    <>
                    </> :
                    <>
                        <Fabric>
                            <div className={classNames.controlWrapper}>
                                {this.state.isOKVisible ? 
                                    <Dialog
                                        hidden={!this.state.isOKVisible}
                                        dialogContentProps={{
                                            type: DialogType.normal,
                                            title: "Erfolgreich gespeichert!",

                                        }}
                                        modalProps={{

                                            isBlocking: true,
                                            containerClassName: 'ms-dialogMainOverride'
                                        }}
                                    >
                                        <DialogFooter>
                                            <Button size="sm" block variant="primary" onClick={() => { this.setState({ isOKVisible: false }) }}>OK</Button>
                                        </DialogFooter>
                                    </Dialog> : 
                                    <></>
                                }
                                {this.state.isRemoveDialog ? 
                                    <Dialog
                                        hidden={!this.state.isRemoveDialog}
                                        onDismiss={(e) => { this.hideDialog(e) }}
                                        dialogContentProps={{
                                            type: DialogType.normal,
                                            title: "Möchtest Du das mTan Verfarhen für "+ (this._selection.getSelection()[0] as mTanHandlerModel).accountID +" aufheben?",

                                        }}
                                        modalProps={{
                                            isBlocking: true,
                                            containerClassName: 'ms-dialogMainOverride'
                                        }}>
                                        <DialogFooter>
                                            <Button variant="danger" onClick={() => {this.removeMTan(''); this.setState({ isRemoveDialog: false, isPanelOpen: false, mode: EScreenMode.view, lastSelectedPivot: "initial" }) }}>Ja</Button>
                                            <Button variant="secondary" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { this.hideDialog(e) }}>Abbrechen</Button>
                                        </DialogFooter>
                                    </Dialog> : 
                                    <></>
                                }
                                {this.state.isAddDialog ? 
                                    <Dialog
                                        hidden={!this.state.isAddDialog}
                                        onDismiss={(e) => { this.hideDialog(e) }}
                                        dialogContentProps={{
                                            type: DialogType.normal,
                                            title: "Soll der VOID: "+ this.addVoidToMTan +" das mTan Verfahren hinzugefügt werden?",

                                        }}
                                        modalProps={{
                                            isBlocking: true,
                                            containerClassName: 'ms-dialogMainOverride'
                                        }}
                                    >
                                        <DialogFooter>
                                            <Button variant="danger" onClick={() => {this.addMTan(); this.setState({ isAddDialog: false, isPanelOpen: false, mode: EScreenMode.view, lastSelectedPivot: "initial" }) }}>Ja</Button>
                                            <Button variant="secondary" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { this.hideDialog(e) }}>Abbrechen</Button>
                                        </DialogFooter>
                                    </Dialog> : 
                                    <></>
                                }
                            </div>
                            {isModalSelection ? 
                                (this.state.spinner ? 
                                    <Spinner size={SpinnerSize.large} /> :
                                    <>
                                    <div>
                                        <Row>
                                            <Col>
                                                <TextField
                                                    errorMessage={this.state.error}
                                                    placeholder={"VOID dem mTan Verfahren hinzufügen."}
                                                    onChange= {this.addVoid}
                                                    type="number"
                                                    maxLength={8}
                                                /><br></br>
                                                <Button variant="primary" onClick={() => { this.showAddDialog() }}>Händler Hinzufügen</Button>
                                            </Col>
                                            <Col><Text>Anzahl der Händler mit mTan-Verfahren: {this._allHandlerItems.length}</Text>
                                                <SearchBox 
                                                    placeholder="Suche"
                                                    onSearch={this.onNewSearchRequest}
                                                    onClear={this.onClear}
                                                    onBlur={() => console.log('onBlur called')}
                                                    underlined={true} 
                                                    maxLength={30}
                                                />
                                                <Text>{this.state.announcedMessage}</Text>
                                            </Col>
                                            <Col><ExportCSV csvData={this._allHandlerItems} fileName={'export'} /></Col>
                                        </Row>
                                    </div>
                                        <DetailsList
                                            items={handlerItems}
                                            compact={isCompactMode}
                                            columns={columns}
                                            selectionMode={SelectionMode.single}
                                            setKey="single"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            selection={this._selection}
                                            selectionPreservedOnEmptyClick={true}
                                            enterModalSelectionOnTouch={true}
                                            ariaLabelForSelectionColumn="Toggle selection"
                                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                            checkButtonAriaLabel="Row checkbox"
                                        />
                                    </>
                                ) : 
                                (
                                    <DetailsList
                                        items={handlerItems}
                                        compact={isCompactMode}
                                        columns={columns}
                                        selectionMode={SelectionMode.single}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                    />
                                )}
                        </Fabric>
                    </>
        )
    }


    private onDataArrival(api: IApiResponse) {

        if (api.data !== undefined) {

            this._allHandlerItems = api.data;
            this.totalItems = Number(api.data.countModel);

            this.setState({
                mode: EScreenMode.view,
                handlerItems: api.data,
                spinner: false,
                selectionDetails: `${this.totalItems} Händler insgesamt`
            });
        }
    }


    private addVoid = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: any): any => {
        if(text.lenght > 8){
            this.setState({ error: "Geben Sie bitte mind. 8 Ziffern ein." })
        }
        else{
            this.addVoidToMTan = text;
        }
    }

    private addMTan(){
        this.setState({ spinner: true, error: '' })
                   
        Api.fetch(this.url + 'addRemoveMTan?' + 'accountID=' + this.addVoidToMTan + '&tanAuthExCustomer=true' , undefined, "POST", 
        (api: IApiResponse) => {
            try {
                Api.fetch(this.url + 'getAccountsWithMTanValidation?', undefined, "GET", this.onDataArrival);
                this.setState({ changed: true });   
            } catch (error) {
                console.log(error)
                this.setState({error:''})
            }
        });

    }

    private removeMTan(e?: any) {
        
        this.setState({ spinner: true, error: '' });
        let id: string = this._selection.getSelection()[0] === undefined ? '' : (this._selection.getSelection()[0] as mTanHandlerModel).accountID;

        Api.fetch(this.url + 'addRemoveMTan?' + 'accountID=' + id + '&tanAuthExCustomer=false' , undefined, "POST",
        (api: IApiResponse) => {
            try {
                Api.fetch(this.url + 'getAccountsWithMTanValidation?', undefined, "GET", this.onDataArrival);
                this.setState({ changed: true });   
            } catch (error) {
                console.log(error)
                this.setState({error:''})
            }
        });
    }

    private hideDialog(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        this._selection.setAllSelected(false);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isRemoveDialog: false, isPanelOpen: true })
            this.setState({ isAddDialog: false, isPanelOpen: true })
        }
    }
    private showRemoveDialog() {
        this.setState({ isRemoveDialog: true, isPanelOpen: true })
    }

    private showAddDialog() {
        this.setState({ isAddDialog: true, isPanelOpen: true })
    }


    private _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();
        var dealerCount = 0;
        if (this.state && this.state.handlerItems) {
            dealerCount = this.totalItems;
        }

        switch (selectionCount) {
            case 0:
                return `${dealerCount} Händler insgesamt`;
            case 1:
                return '1 Händler ausgewählt: ' + (this._selection.getSelection()[0] as mTanHandlerModel).accountID;
            default:
                return `${selectionCount} items selected`;
        }
    }

    private onClear = (newValue: any) => {
        this.setState({
            mode: EScreenMode.view,
            handlerItems: this._allHandlerItems,
            announcedMessage: ""
        });
    }

    private onNewSearchRequest = (newValue: string) => {

        var newHandlerList: mTanHandlerModel[] = [];

        if(newValue)
        if (newValue.length < 4){
            this.setState({
                mode: EScreenMode.view,
                handlerItems: this._allHandlerItems,
                announcedMessage: "Minimum 4 Zeichen."
            });
        }else{

            this._allHandlerItems.forEach((newHanlder: mTanHandlerModel) => {
                if(newHanlder.accountID.includes(newValue)||newHanlder.companyName.includes(newValue)){
                    newHandlerList.push(newHanlder)
                }
            })

            if(newHandlerList.length >= 1){

                this.setState({
                    mode: EScreenMode.view,
                    handlerItems: newHandlerList,
                    announcedMessage: ""
                });
            } else {

                this.setState({
                    mode: EScreenMode.view,
                    handlerItems: this._allHandlerItems,
                    announcedMessage: "Die Suche hat keine Treffer ergeben."
                });
            }
        }
    };


    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {

        const { columns, handlerItems } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                // this.setState({
                //     announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`
                // });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(handlerItems, currColumn.fieldName!, currColumn.isSortedDescending);

        //  if (this._selection.getSelectedCount() === 1 && this.state.mode===EScreenMode.edit) {
        //     this.setState({ selectionDetails: this._getSelectionDetails(),columns:newColumns,handlerItems:newItems })
        // }else{
        this._selection.setAllSelected(false);

        this.setState({
            mode: EScreenMode.view,
            handlerItems: newItems,
            spinner: false,
            columns: newColumns,
            selectionDetails: `${this.totalItems} Händler insgesamt`
        });

        // this.setState({
        //     columns: newColumns,
        //     handlerItems: newItems,
        //     mode: EScreenMode.view,
        //     lastSelectedPivot: 'initial',
        //     selectionDetails: this._getSelectionDetails()
        // });
    };

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}


export default MTanValidation;
import { initializeIcons } from '@uifabric/icons';
import { IRawStyle, mergeStyles, mergeStyleSets } from '@uifabric/merge-styles';
import linq from 'linq';
import { Checkbox, ContextualMenu, DatePicker, Dialog, DialogFooter, DialogType, DirectionalHint, Dropdown, IContextualMenuItem, IDatePickerStrings, Panel, PanelType } from 'office-ui-fabric-react';
import { Fabric, IDropdownOption, ITextFieldStyles, ProgressIndicator, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react/lib';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../src/App.css';
import { EScreenMode } from '../model/app/Enums';
import { IApiResponse } from '../model/app/IApiResponse';
import IKeyText from '../model/app/IKeyText';
import { MoreInfoShop } from '../model/data/HandlerModel';
import { ListValueOption } from '../model/data/ListValueOption';
import { Representative, RepresenterUser } from '../model/data/Representative';
import { ShopEmployeeModel } from '../model/data/ShopEmployeeModel';
import { Api } from '../utils/Api';
import { StorageKeys } from '../utils/Defaults';
import StorageHelper from '../utils/StorageHelper';
import { TablePaging } from './paging';

// Initialize icons in case this example uses them
initializeIcons('https://res.cdn.office.net/files/fabric-cdn-prod_20240129.001/assets/icons/');


const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px'
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden'
            }
        }
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px'
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px'
    },
    selectionDetails: {
        marginBottom: '20px'
    }
});
const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px'
    }
};

export interface Positions {
    Id: number;
    Text: string;
}

export interface Positions {
    Id: number;
    Text: string;
}

export interface UserStatuses {
    Id: number;
    Name: string;
}

const commonStyles: IRawStyle = {
    display: 'inline-block',
    cursor: 'default',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    background: 'none',
    backgroundColor: 'transparent',
    border: 'none',
};
const classNamesnew = mergeStyleSets({
    item: {
        selectors: {
            '&:hover': { background: '#eee' },
        },
    },
    // Overwrites the default style for Button
    check: [commonStyles, { padding: '11px 8px' }],
    cell: [
        commonStyles,
        {
            overflow: 'hidden',
            height: 36,
            padding: 8,
        },
    ],
});



const exampleChildClass = mergeStyles({
    display: 'block',
    marginBottom: '10px',

});

const iconClass = mergeStyles({
    fontSize: 25,
    height: 25,
    width: 25,
    color: '#C0C0C0',
    margin: '0 25px',
});

export interface IPagingModel {
    page: number;
    count: number;
}

export interface ITablePagingProps {

    page: number;
    count: number;
}



interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    // items: IDocument[];
    representativeItems: Representative[];
    // allEmployees: ShopEmployeeModel[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
    announcedMessage?: string;
    mode: EScreenMode,
    changed: boolean,
    selectedEmployeeToEdit: ShopEmployeeModel,
    hidden: boolean,
    disableButtonNameField: boolean,
    lastSelectedPivot: string | undefined,
    spinner: boolean,
    isPanelOpen: boolean,
    isDialogVisible: boolean,
    error: string,
    isOKVisible: boolean,
    pagingModel: IPagingModel;
    errorResponse: boolean;
    contextMenu: any;
    noEndDate: boolean;
    selectedRepresentative: Representative;

}

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Are you sure you want to close the panel?',
};
const dialogModalProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
};

const DayPickerStrings: IDatePickerStrings = {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker'
};


const narrowTextFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { height: 30, width: 300 } };
const buttonStyles = { root: { marginRight: 8 } };


class Representatives extends React.Component<{}, IDetailsListDocumentsExampleState> {
    private url: string = "activationdata/";
    private _selection: Selection;
    // private _allItems: IDocument[];
    private _allRepresentativesItems: Representative[];
    private user = StorageHelper.getUser();
    // private allEmployees: ShopEmployeeModel[];
    //  private shopManagers: ShopEmployeeModel[];
    //  private previewEmployees: ShopEmployeeModel[];
    // private selectedRepresentativeToEdit: ShopEmployeeModel = { id: '', employee_void: '', void: '', userType: '', status: 0, position: '', email: '', salutation: '', firstName: '', surname: '', mobile: '', email2: '', shopmanager: 0 };
    // private allPositions: IDropdownOption[];
    private allUserStatuses: IDropdownOption[];
    private shopVoidToSearch: string = '';
    private tempInfo: MoreInfoShop = new MoreInfoShop();
    private totalItems: number = 0;
    private howManypagToRender: number = 0;
    private currentPage: number = 1;
    private errorResponseMessage: string = '';
    private allRepresentatives: RepresenterUser[] = StorageHelper.getRepresentativesOptions();
    private selectedRepresentative: Representative = new Representative();

    // private oneRepresentative: Representative = { username: 'FRM', origin_User: '8655C6AA-14F3-4FB4-B3F3-197B99244498', AccountFilterTypeId: 1, origin_filtervalue: '40SPGEB01', origin_Name: 'Michael van Bree', is_virtual: false, begin_date: new Date(), end_date: new Date(), rep_status: 'abgelaufen' }



    constructor(props: {}) {
        super(props);


        this._allRepresentativesItems = [];

        this.allUserStatuses = [];
        this._refreshModel = this._refreshModel.bind(this);
        this._hidePanel = this._hidePanel.bind(this);
        this.onDataArrival = this.onDataArrival.bind(this);
        this._onNo = this._onNo.bind(this);
        // this._onYes = this._onYes.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.onDataArrival = this.onDataArrival.bind(this);
        this._hidePanel = this._hidePanel.bind(this);
        this._onNo = this._onNo.bind(this);
        this.onItemContextMenu = this.onItemContextMenu.bind(this);
        this._onContextMenuItemClicked = this._onContextMenuItemClicked.bind(this);
        this._onContextMenuDismiss = this._onContextMenuDismiss.bind(this);
        this.prepareNewRepresentation = this.prepareNewRepresentation.bind(this)



        const columns: IColumn[] = [
            {
                key: 'Name',
                name: 'Vertretung',
                ariaLabel: 'Vertretung',
                isRowHeader: true,
                fieldName: 'Name',
                minWidth: 100,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',

                maxWidth: 150,
                //onColumnClick: this._onColumnClick
            },
            {
                key: 'StartDate',
                name: 'von',
                ariaLabel: 'von',
                fieldName: 'StartDate',
                minWidth: 90,
                isSorted: true,
                // isSortedDescending: false,
                // sortAscendingAriaLabel: 'Sorted A to Z',
                // sortDescendingAriaLabel: 'Sorted Z to A',

                maxWidth: 110,

                onRender: (item: Representative) => {
                    return <span>{item.StartDate ? item.StartDate.toString().substring(0, 10) : 'n.a'}</span>;
                },

                // onColumnClick: this._onColumnClick
            },
            {
                key: 'EndDate',
                name: 'bis',
                fieldName: 'EndDate',
                minWidth: 90,
                maxWidth: 110,
                isResizable: true,
                isSorted: true,
                // isSortedDescending: false,
                // sortAscendingAriaLabel: 'Sorted A to Z',
                // sortDescendingAriaLabel: 'Sorted Z to A',
                // onColumnClick: this._onColumnClick,

                onRender: (item: Representative) => {
                    return <span>{item.EndDate ? item.EndDate.toString().substring(0, 10) : 'Ende offen'}</span>;
                },


            }
            // {
            //     key: 'rep_status',
            //     name: 'Status',
            //     fieldName: 'rep_status',
            //     minWidth: 20,
            //     maxWidth: 30,
            //     isResizable: true,
            //     isSorted: true,
            //     // isSortedDescending: false,
            //     // sortAscendingAriaLabel: 'Sorted A to Z',
            //     // sortDescendingAriaLabel: 'Sorted Z to A',
            //     // onColumnClick: this._onColumnClick,

            //     isPadded: true
            // }
        ];

        this._selection = new Selection({
            onSelectionChanged: () => {

                this.setState({ changed: true, selectedRepresentative: this._selection.getSelection()[0] as Representative })

            }
        });

        this.state = {

            representativeItems: [], // substitude after fetching!!!
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: true,
            isCompactMode: false,
            announcedMessage: undefined,
            mode: EScreenMode.loading,
            hidden: true,
            changed: false,
            lastSelectedPivot: undefined,
            selectedEmployeeToEdit: {
                id: '',
                employee_void: '',
                void: '',
                userType: '',
                status: 0,
                position: '',
                email: '',
                salutation: '',
                firstName: '',
                surname: '',
                mobile: '',
                email2: '',
                shopmanager: 0,userStatusId:0
            },
            disableButtonNameField: true,
            spinner: false,
            isPanelOpen: false,
            isDialogVisible: false,
            error: '',
            isOKVisible: false,
            pagingModel: { page: 1, count: 11000 },
            errorResponse: true,
            contextMenu: {
                target: document.createElement('div'),
                items: []
            },
            noEndDate: false,
            selectedRepresentative: new Representative()

        };

        if (this.user) {
            Api.fetch(this.url + 'getAllUserRepresentationsByUserId?' + 'id=' + this.user!.username, undefined, "GET", this.onDataArrival);
        }


    }

    public render() {
        const { columns, isCompactMode, representativeItems, selectionDetails, isModalSelection, announcedMessage } = this.state;

        let t1 = 'Löschvorgang bestätigen';
        let t2 = 'Sind sie sicher, dass der Mitarbeiter gelöscht werden soll?';


        return (

            this.state.mode === EScreenMode.loading ? <ProgressIndicator label="Daten werden geladen" description="Bitte warten Sie" /> :


                this.state.hidden === false ?

                    <>
                        <Dialog
                            hidden={this.state.hidden}
                            onDismiss={this._onNo}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: t1,
                                subText: t2
                            }}
                            modalProps={{
                                titleAriaId: t1,
                                subtitleAriaId: t2,
                                isBlocking: false,
                                containerClassName: 'ms-dialogMainOverride'
                            }}
                        >
                            <DialogFooter>
                                {/* <Button variant="danger" onClick={this._onYes}>Ja</Button> */}
                                <Button variant="secondary" onClick={this._onNo}>Abbrechen</Button>
                            </DialogFooter>
                        </Dialog>
                    </> :


                    <>
                        <Fabric>
                            <div className={classNames.controlWrapper}>
                                {this.state.isOKVisible ? <Dialog
                                    hidden={!this.state.isOKVisible}
                                    dialogContentProps={{
                                        type: DialogType.normal,
                                        title: "Erfolgreich gespeichert!",

                                    }}
                                    modalProps={{

                                        isBlocking: true,
                                        containerClassName: 'ms-dialogMainOverride'
                                    }}
                                >
                                    <DialogFooter>
                                        <Button size="sm" block variant="primary" onClick={() => { this.setState({ isOKVisible: false }) }}>OK</Button>
                                    </DialogFooter>
                                </Dialog> : <></>}
                                {this.state.isDialogVisible ? <Dialog
                                    hidden={!this.state.isDialogVisible}
                                    onDismiss={(e) => { this.hideDialog(e) }}
                                    dialogContentProps={{
                                        type: DialogType.normal,
                                        title: "Möchten Sie dieses Fenster wirklich schließen?",

                                    }}
                                    modalProps={{
                                        titleAriaId: t1,

                                        isBlocking: true,
                                        containerClassName: 'ms-dialogMainOverride'
                                    }}
                                >
                                    <DialogFooter>

                                        <Button variant="danger" onClick={() => { this.setState({ isDialogVisible: false, isPanelOpen: false, mode: EScreenMode.view, lastSelectedPivot: "initial" }) }}>Ja</Button>
                                        <Button variant="secondary" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { this.hideDialog(e) }}>Abbrechen</Button>
                                    </DialogFooter>
                                </Dialog> : <></>}


                                {/* <Row > */}
                                {/* <Col>
                                        <SearchBox
                                            placeholder="Search"
                                            onFocus={() => console.log('onFocus called')}
                                            onBlur={() => console.log('onBlur called')}
                                            onSearch={this.onNewSearchRequest}
                                            underlined={true}/>
                                    </Col> */}

                               

                                {/* <Col xs={10} md={10} >

                                            {<TextField
                                                className={exampleChildClass}
                                                errorMessage={this.state.error}
                                                onKeyDown={(e) => { this.onNewSearchRequest(e) }}
                                                placeholder={"Eingabe von mind. 4 Ziffern."}
                                                onChange={this.gatherVoidtext}
                                                styles={narrowTextFieldStyles}
                                            />}

                                        </Col>

                                        <Col xs={2} md={2}>
                                            <Button size="sm" id={"search"} variant="primary" onClick={(e: any) => { this.onNewSearchRequest(e) }}>Suchen</Button>
                                        </Col>
                                    </Row> */}


                            </div>
                            {/* <div className={classNames.selectionDetails}>{selectionDetails}</div> */}

                            {isModalSelection ? (
                                this.state.spinner ? <Spinner size={SpinnerSize.large} /> :
                                    <>

                                        <TablePaging model={this.state.pagingModel} parent={this} pagesToRender={5} totalItemsCount={this.totalItems} />
                                        {this.state.contextMenu ? (
                                            <ContextualMenu
                                                onItemClick={this._onContextMenuItemClicked}
                                                items={this.state.contextMenu.items}
                                                isBeakVisible={true}
                                                target={this.state.contextMenu.target}
                                                directionalHint={DirectionalHint.bottomLeftEdge}
                                                onDismiss={this._onContextMenuDismiss} />
                                        ) : null
                                        }
                                        {<>
                                            <Row>
                                                <Col xs={4} md={4}>
                                                    <Button onClick={this.prepareNewRepresentation}>+ neu vertretung</Button>
                                                </Col>
                                            </Row>
                                        </>}
                                        <DetailsList
                                            items={representativeItems}
                                            compact={isCompactMode}
                                            columns={columns}
                                            selectionMode={SelectionMode.single}
                                            getKey={this._getKey}
                                            setKey="single"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            selection={this._selection}
                                            selectionPreservedOnEmptyClick={true}
                                            onItemContextMenu={this.onItemContextMenu}
                                            onItemInvoked={this._onItemInvoked}
                                            enterModalSelectionOnTouch={true}
                                            ariaLabelForSelectionColumn="Toggle selection"
                                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                            checkButtonAriaLabel="Row checkbox"
                                        />
                                    </>

                            ) : (
                                    <DetailsList
                                        items={representativeItems}
                                        compact={isCompactMode}
                                        columns={columns}
                                        selectionMode={SelectionMode.single}
                                        getKey={this._getKey}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        onItemInvoked={this._onItemInvoked}
                                    />
                                )}
                            {this._selection.getSelection()[0] === undefined && this.state.mode !== EScreenMode.new ? <></> : this.renderEditor()}
                        </Fabric>
                    </>
        );
    }

    private onSaveModel() {
        if (this.state.noEndDate) {
            this.state.selectedRepresentative.EndDate = undefined;
        }

        Api.fetch(this.url + 'saveOrEditRepresentation', this.state.selectedRepresentative, "POST",
            (api: IApiResponse) => {

                if (api.data) {
                    let data = JSON.parse(api.data.Content)
                    this._allRepresentativesItems = data.data.representations;
                    this.selectedRepresentative = new Representative();

                    this._selection.setAllSelected(false)

                    this.setState({
                        mode: EScreenMode.view,
                        selectedRepresentative: new Representative(),
                        representativeItems: this._allRepresentativesItems,
                    });
                }

            });
    }

    private prepareNewRepresentation() {

        this._selection.setAllSelected(false)

        this.selectedRepresentative = new Representative();

        this.setState({ mode: EScreenMode.new, selectedRepresentative: new Representative() })

    }

    private _onContextMenuDismiss(): void {
        this.setState({
            contextMenu: undefined
        });
    };

    private _onContextMenuItemClicked(ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) {
        if (item && item.key == 'new') {
            this._selection.setAllSelected(false);
            this.selectedRepresentative = new Representative();

            this.setState({

                mode: EScreenMode.new,
                selectedRepresentative: new Representative()
            });

        } else if (item && item.key == 'edit') {
            this.selectedRepresentative = this._selection.getSelection()[0] as Representative;
            this.setState({ mode: EScreenMode.edit, selectedRepresentative: this._selection.getSelection()[0] as Representative })
        } else {
            Api.fetch(this.url + 'deleteRepresentation', this.state.selectedRepresentative, "POST",
                (api: IApiResponse) => {

                    if (api.data) {
                        let data = JSON.parse(api.data.Content)
                        this._allRepresentativesItems = data.data.representations;
                        this._selection.setAllSelected(false);

                        this.setState({
                            mode: EScreenMode.view,
                            representativeItems: this._allRepresentativesItems,
                        });
                    }

                });
        }
    };

    private onItemContextMenu(item?: any, index?: number, ev?: Event) {
        if (item !== undefined) {

            // this.currentService = item;

            let actionItems: any[] = [];

            let target = ev ? ev.target : undefined;

            if (target !== undefined) {
                actionItems.push({ key: 'new', iconProps: { iconName: 'Add' }, text: 'Neu' });
                actionItems.push({ key: 'edit', iconProps: { iconName: 'Edit' }, text: 'Bearbeiten' });
                actionItems.push({ key: 'delete', iconProps: { iconName: 'Delete' }, text: 'Löschen' });


                if (ev) {
                    ev.preventDefault();
                }
                this.setState({
                    contextMenu: {
                        target: target as HTMLElement,
                        items: actionItems
                    },
                    // currentItem: item
                });


            }
        }
    };


    onPaginationPageChanged(page: number) {

        // let { pagingModel } = this.state;
        // this.setState({ spinner: true })
        // pagingModel.page = page;
        // this.currentPage = page;
        // Api.fetch(this.url + 'getHandlerInformation?' + 'shopVoidorNameModel=' + ' ' + '&PageNumberModel=' + page + '&RowsOfPageModel=' + 500 + '&countModel=' + 0, undefined, "GET", this.onDataArrival);

        // this.setState({ pagingModel });
    }

    private _onItemInvoked(item: any): void {

    }

    private gatherVoidtext = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: any): any => {
        // this.shopVoidToSearch = text;
        // if (this.shopVoidToSearch === '') {
        //     this._selection.setAllSelected(false);
        //     this.setState({ spinner: true, error: '', selectionDetails: this._getSelectionDetails() })
        //     // Api.fetch(this.url + 'getHandlerInformation/', undefined, "GET", this.onDataArrival);
        //     Api.fetch(this.url + 'getHandlerInformation?' + 'shopVoidorNameModel=' + ' ' + '&PageNumberModel=' + 1 + '&RowsOfPageModel=' + 500 + '&countModel=' + 0, undefined, "GET", this.onDataArrival);


        // }
    }

    private _onNo() {
        this.setState({ hidden: true });
    };

    // private _onYes() {
    //     let index = this.allEmployees.findIndex(x => x.employee_void === this.selectedEmployeeToEdit.employee_void);
    //     this.allEmployees.splice(index, 1);
    //     Api.fetch(this.url + 'deleteEmployee', this.selectedEmployeeToEdit, "POST",
    //         (api: IApiResponse) => {

    //             this.setState({ hidden: true, changed: true });
    //         });
    // };


    public componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    private _getKey(item: any, index?: number): string {
        return item.key;
    }


    private renderEditor(): JSX.Element {
        // this.selectedRepresentative = this._selection.getSelection()[0] === undefined ? new Representative() : (this._selection.getSelection()[0] as Representative);
        this.state.selectedRepresentative.LoggedInUser = this.user ? this.user.username : '';
        let representativesOptions: IKeyText[] = [];

        if (this.allRepresentatives) {
            representativesOptions = linq.from<RepresenterUser>(this.allRepresentatives)
                .select(x => ListValueOption.createInstance(x.OriginName, x.OriginUser, false))
                .select<IKeyText>(x => ({ key: x.value, text: x.text,disabled:this.user && this.user.Fullname == x.text}))
                .toArray();
        }

        let ctrl = <></>

        ctrl =
            <>
                <Panel isFooterAtBottom={true} onRenderFooterContent={() => {
                    return <Button onClick={() => {
                        this.onSaveModel()
                    }
                    }>Vertretung speichern</Button>;
                }} isBlocking={true} headerText={"Vertretung bearbeiten"} isLightDismiss={false} onLightDismissClick={this.showDialog} isOpen={this.state.mode === EScreenMode.edit || this.state.mode === EScreenMode.new} onDismiss={this.showDialog} type={PanelType.medium} >
                    {/* <div onClick={() => { this.setState({ mode: EScreenMode.edit, lastSelectedPivot: "allEmployeesKey" }) }}> <FontIcon iconName="Back" className={iconClass} /> </div> */}
                    {

                        <>
                            <Container>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <TextField label="Vertriebsbeauftragter" name="Vertriebsbeauftragter" required value={this.user ? this.user.firstname + " " + this.user.surname : ''} disabled={true} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Dropdown placeholder="" required={true}
                                            id="representedBy"
                                            label="Vertreten durch"
                                            selectedKey={this.state.selectedRepresentative.RepresenterId ? this.state.selectedRepresentative.RepresenterId : undefined}
                                            options={representativesOptions}
                                            onChanged={(option) => {

                                                this.selectedRepresentative.RepresenterId = option.key.toString();
                                                this.setState({ selectedRepresentative: this.selectedRepresentative })

                                            }} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12}>
                                        <DatePicker label="Startdatum"
                                            strings={DayPickerStrings}
                                            isRequired={true}
                                            value={typeof this.state.selectedRepresentative.StartDate === 'string' ? new Date(this.state.selectedRepresentative.StartDate.toString().substring(0, 10)) : this.state.selectedRepresentative.StartDate}
                                            placeholder="Select a date..." ariaLabel="Select a date" onSelectDate={(e) => { this.selectedRepresentative.StartDate = e ? e : undefined; this.setState({ selectedRepresentative: this.selectedRepresentative }) }} />
                                    </Col>
                                </Row>
                                <br />
                                <Row >
                                    <Col xs={6} md={6}>
                                        <Checkbox checked={this.state.noEndDate} name="" label="ohne Enddatum" onChange={() => { this.setState({ changed: true, noEndDate: !this.state.noEndDate }) }} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12}>
                                        <DatePicker label="Enddatum" strings={DayPickerStrings}
                                            disabled={this.state.noEndDate}
                                            isRequired={true}
                                            value={typeof this.state.selectedRepresentative.EndDate === 'string' ? new Date(this.state.selectedRepresentative.EndDate.toString().substring(0, 10)) : this.state.selectedRepresentative.EndDate}
                                            placeholder="Select a date..." ariaLabel="Select a date" onSelectDate={(e) => { this.selectedRepresentative.EndDate = e ? e : undefined; this.setState({ selectedRepresentative: this.selectedRepresentative }) }} />
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    }
                </Panel>
            </>

        return ctrl;
    };

    private _refreshModel(ctrl: any) {

        this.setState({ changed: true });

    };


    private hideDialog(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isDialogVisible: false, isPanelOpen: true })
        }
    }
    private showDialog() {
        this.setState({ isDialogVisible: true, isPanelOpen: true })
    }

    private _getSelectionDetails(): string {

        const selectionCount = this._selection.getSelectedCount();
        var dealerCount = 0;
        if (this.state && this.state.representativeItems) {
            dealerCount = this.totalItems;
        }


        switch (selectionCount) {
            case 0:
                return `${dealerCount} Vertretung insgesamt`;
            case 1:
                return '1 Vertretung ausgewählt: ' + (this._selection.getSelection()[0] as Representative).Name;
            default:
                return `${selectionCount} items selected`;
        }
    }

    private _hidePanel() {

        this.setState({ mode: EScreenMode.view, lastSelectedPivot: "initial" });

    };


    private onDataArrival(api: IApiResponse) {

        if (api.data !== undefined) {
            this._allRepresentativesItems = api.data.representations;

            if (api.data.representationOptions) {
                StorageHelper.set(StorageKeys.representationOptions, api.data.representationOptions)
                this.allRepresentatives = api.data.representationOptions;
            }

            this.setState({
                mode: EScreenMode.view,
                representativeItems: this._allRepresentativesItems,
                spinner: false,
                selectionDetails: `${this.totalItems} Vertretung insgesamt`,
                pagingModel: { page: this.currentPage, count: this.totalItems }
            });

        }

    }

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}


export default Representatives;